import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarComponent } from '../app/shared-components/toast/snackbar.component';
import { SnackbarStatus } from '../definitions/snackbar-status.enum';

/**
 * Shape files Service
 *
 * This service retrieves the region files from the API
 */
@Injectable({
  providedIn: 'root'
})
export class CustomSnackbarService {
  private snackBar = inject(MatSnackBar);

  private defaultDuration = 3000;

  /**
   * Returns the icon name based on the snackbar status type.
   *
   * @param type The type of the message. It can be 'success', 'error', or 'info'
   * @returns The icon name as a string
   */
  private getIconByType(type: SnackbarStatus): string {
    const icons = {
      [SnackbarStatus.Success]: 'checkmark-circle-outline',
      [SnackbarStatus.Error]: 'alert-circle-outline',
      [SnackbarStatus.Info]: 'information-circle-outline'
    };

    return icons[type] || icons.info;
  }

  /**
   * Presents a snackbar message with a custom icon.
   *
   * @param message The message to display
   * @param type The type of the message. It can be 'success', 'error', or 'info'
   * @returns A reference to the snackbar
   */
  present(message: string, type: SnackbarStatus = SnackbarStatus.Info): MatSnackBarRef<any> {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message, icon: this.getIconByType(type) },
      duration: this.defaultDuration,
      panelClass: `mat-snack-bar-${type}`
    });
  }
}
