/**
 * Defines the possible statuses of a snackbar message.
 * - Success. Represent a success event: new item created, user submitted a form, etc.
 * - Error. Represent an error event: user tried to submit a form with invalid data, network error, etc.
 * - Info. Represent an information event: invitation sent, changes that are not critical, etc.
 */
export enum SnackbarStatus {
  Success = 'success',
  Error = 'error',
  Info = 'info'
}
