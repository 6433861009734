import { Component, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IonIcon } from 'src/directives/icon';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  standalone: true,
  imports: [IonIcon]
})
export class SnackbarComponent {
  public data: { message: string; icon: string } = inject(MAT_SNACK_BAR_DATA);
}
